@import "bootstrap";

body {
  margin: 15px;
  background-color: aliceblue;
}

.container {
  width: 1200px;
}

.form-container{
  border: solid 1px #5cb85c!important; 
  background-color: #ffffff!important; 
  margin: 25px 0px!important; 
  padding: 0 30px 20px 30px!important;
}
.form-table-container{
  border: solid 1px #5cb85c!important; 
  background-color: #ffffff!important; 
  margin: 0px 0px!important; 
  padding: 0 30px 20px 30px!important;
  max-width: 1200px;
}

.navbar-nav > li > a {
  padding-top: 15px;
  padding-bottom: 18px;
}

td {
  white-space: nowrap;
}

table th.th_wrap_header{
  white-space:normal!important;
  min-width:250px!important;
}

//remove arrow controls in numeric input
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;//firefox compatibile
}

/*input file type*/
input[type="file"] {
    display: none;
}
.custom-file-upload {
    cursor: pointer;
    display: inline-block;
}

/**
 * First column of editable tables
 * - Lock position (similar to lock column in Excel)
 */
.first-col {
  position: absolute;
  width: 12em;
  margin-left: -12em;
  padding-bottom: 7px; /* Fix issue with alignment with other headers */
}

.table-wrapper {
  margin-top: 5px;
}

.w1000{
  width: 1000px;
}

.w1200{
  width: 1200px;
}
/**
 * Editable Table Headers
 * - Don't line wrap
 */
.table-wrapper th {
  white-space: nowrap;
}

html {
  overflow-y: scroll;
}

span.alert{color: #8d1e64; font-weight: bold;}

.library-item {
  min-height: 150px;
}

.navbar-inverse .navbar-brand {
  color: #8d1e64
}

.navbar-inverse .navbar-nav > li > a {
  color: #8d1e64;
  line-height: 2;
}

.navbar-inverse {
  background-color: rgb(216, 189, 189);
  border-color: #735757;
  border-radius: 10px;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #978484;
  line-height: 2;
}

.vtop {
  vertical-align: top !important;
  word-wrap: break-word;
}

input[disabled], label[disabled] {
  cursor: not-allowed;
}

.btn {
  font-size: 13px;
  padding: 3px 9px 3px 9px;
}

.btn2 {
  font-size: 13px;
}

.not-bold {
  font-weight: normal !important;
}

.form-control .ember-text-field .ember-view {
  width: 1000px;
}

.form-control .ember-text-field {
  width: 1000px;
}

.export {
  display: none;
}

.mt4{
  margin-top: 4px;
}

textarea.read{
  /*background-color: #f7f7f7;*/
  background: transparent;
  border: transparent;
}


.mt5{
  margin-top: 5px;
}

.mt10{
  margin-top: 10px;
}

.mt20{
  margin-top: 20px;
}

/**
 * Issue: Input boxes in table cells are too small to type in
 * Fix: Ensure input boxes have a reasonable min size
 */

input.form-control {
  min-width: 150px;
  height: 30px;
  padding: 2px 5px;  
}

select.select-box{
  min-width: 174px;
}

input.form-box, input.form-large-box, select{
  height: 30px;
  padding: 2px 5px;  
}

input.form-large-box{
  min-width: 400px;  
}

input, select{
  border: solid 1px #cccccc;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaaaaa;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaaaaa;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #aaaaaa;
}
/**
 * Add this class to a data table to freeze the first column
 */
table.freeze-col tr td:first-child, table.freeze-col tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: aliceblue;
  font-weight: bold;
}

table thead.freeze-row{
    position: sticky!important;
    top: 0;
    background-color: aliceblue;  
    z-index: 5;  
}

td.cell-selected{
  background-color: #edfad9;
}

#errAddMessage{
  margin-bottom: 0!important;
}

.pop{
  color: #b0000c;
}

table.tbl_list{
  width: 550px;
}

select.wide{
  width:300px;
}

hr {
  border-color: #aaaaaa;
}


hr.action-line{
  margin: 5px 5px 10px 5px;
  border-top:1px dotted #dddddd;
}

.accent{
  color: #5cb85c;
}

.status{
  font-size:14px;
  color:  #5cb85c;
  font-weight: bold;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
  background-color: #428bca;
  display: inline-block;
  font-size: 16px;
  padding: 7px 9px;  
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #337ab7;
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

/* pi network */
.pi-network-table th,
.pi-network-table td
{
  min-width: 100px;
}

.pi-network-table td.wrap-text{
  white-space:normal;
}

.pi-network-form-search, .enlist-form-search{
  display: block;
  width: 100%;
  padding: 15px 15px 10px;
  margin-bottom: 15px;
  background-color: #efefef;
  border: solid 1px lightgray;
}

.pi-network-search-col-1, .enlist-search-col-1,
.pi-network-search-col-2, .enlist-search-col-2,
.pi-network-search-col-3, .enlist-search-col-3 {
  display: inline-block;
  width: fit-content;
  margin: 0 5px;
  padding: 0;
}

.pi-network-search-terms, .enlist-search-terms{
  font-size: 14px;
  color: #8d1e64;
  margin: 10px 5px 0;
  padding: 0;
}